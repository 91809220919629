import React, { useReducer } from "react";

export const AppContext = React.createContext();

const notificationInitialState = {
  isOpen: false,
  messageType: "info",
  message: "",
  timeout: 4000,
};

const initialState = {
  auth: false,
  loading: false,
  roles: sessionStorage.getItem("pages") || ["HOME"],
  rolesError: false,
  notificationState: notificationInitialState,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_LOADER":
      let loadingState = !state.loading;
      if (action.hasOwnProperty("loader")) {
        loadingState = action.loader;
      }
      return {
        ...state,
        loading: loadingState,
      };
    case "USER_LOGIN_SUCCESSFULL":
      return {
        ...state,
        loading: !state.loading,
        auth: action.token,
      };
    case "SET_TOKEN":
      return {
        ...state,
        auth: action.token,
      };
    case "LOGOUT": {
      return {
        ...state,
        auth: false,
        loading: false,
        rolesError: false,
      };
    }
    case "SET_ROLES": {
      sessionStorage.setItem("pages", action.payload.roles);
      sessionStorage.setItem("rolesError", action.payload?.rolesError);
      return {
        ...state,
        roles: action.payload.roles,
        rolesError: action.payload?.rolesError,
        loading: !state.loading,
      };
    }
    case "SHOW_NOTIFICATION": {
      return {
        ...state,
        notificationState: {
          ...notificationInitialState,
          ...action.payload,
          isOpen: true,
        },
      };
    }
    case "CLEAR_NOTIFICATION": {
      return {
        ...state,
        notificationState: { ...notificationInitialState, isOpen: false },
      };
    }
    default:
      return state;
  }
};

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
