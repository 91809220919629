import request from "./request";

export const absoluteCountReset = (obj, selectedQuantity) => {
  const {
    articleId,
    store,
    articleQuantity,
    updateType,
    reason,
    deactivatedTime,
  } = obj;
  const url = `/jmd-rui/api/v1/inventory/update-quantity`;
  const payload =
    selectedQuantity === "virtual"
      ? {
          article: articleId,
          store,
          quantity: articleQuantity,
          updateType,
          reason,
          deactivatedTime,
        }
      : {
          article: articleId,
          store,
          quantity: articleQuantity,
          updateType,
          reason,
        };
  return request({
    method: "POST",
    url,
    data: payload,
  });
};

export const inventoryUpload = (data) => {
  let payload = {};
  if (data.updateType === "VIRTUAL") {
    payload.url = `/jmd-rui/api/v1/inventory/reset`;
  } else {
    payload.url = `/jmd-rui/api/v1/inventory/reset/bulk`;
    payload.headers = { "Content-Type": "multipart/form-data" };
  }
  return request({
    method: "POST",
    data,
    ...payload,
  });
};

export const activeFlagChange = (data) => {
  const { articleId, store, isActive } = data;
  const url = `/jmd-rui/api/v1/inventory/update-status`;
  const payload = {
    article: articleId,
    store,
    isActive,
  };
  return request({
    method: "POST",
    url,
    data: payload,
  });
};

export const uploadDataFile = (fileData, updateType) => {
  const url = `/jmd-rui/api/v1/inventory/bulk-update`;
  const formData = new FormData();
  formData.append("file", fileData);
  formData.append("updateType", updateType);

  return request({
    method: "POST",
    url,
    data: formData,
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const uploadHistory = (pageNo) => {
  const url = `/jmd-rui/api/v1/inventory/upload-history?sort=createdTime&pageNo=${pageNo}`;
  return request({
    method: "GET",
    url,
  });
};

export const viewInventoryCount = (data) => {
  // const { articleId } = data;
  const url = `/jmd-rui/api/v1/inventory/get-list?ids=${data}`;
  return request({
    method: "GET",
    url,
  });
};

export const viewAuditTrail = (data) => {
  const { articleId, store, pageNo } = data;
  const url = `/jmd-rui/api/v1/inventory/history?article=${articleId}&store=${store}&pageNo=${pageNo}&pageSize=10&sort=createdDate`;
  return request({
    method: "GET",
    url,
  });
};

export const getReasons = () => {
  const url = `/jmd-rui/api/v1/inventory/get-reasons/`;
  return request({
    method: "GET",
    url,
  });
};

export const downloadCsv = (data) => {
  const url = `jmd-rui/api/v1/inventory/get-template?uploadType=${data}`;
  return request({
    method: "GET",
    url,
  });
};

export const individualBulkHistoryDetails = (data, pageNo) => {
  const url = `jmd-rui/api/v1/inventory/history?pageNo=${pageNo}&pageSize=10&sort=createdDate&uploadId=${data}`;
  return request({
    method: "GET",
    url,
  });
};

export const DownloadIndividualDetails = (data) => {
  const url = `jmd-rui/api/v1/inventory/history/download/export?uploadId=${data}`;
  return request({
    method: "GET",
    url,
  });
};
