import React, { useState } from "react";
import Navtabs from "../../components/NavTabs";
import InventoryCount from "../../container/InventoryCount";
import VirtualInventory from "../../container/VirtualInventory";
import "./style.scss";

const Home = () => {
  const [showSingleInventory, setShowSingleInventory] = useState(true);

  return (
    <div className="home-page">
      <Navtabs setShowSingleInventory={setShowSingleInventory} />
      {showSingleInventory ? <InventoryCount /> : <VirtualInventory />}
    </div>
  );
};

export default Home;
