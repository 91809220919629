import { Alert, Snackbar } from "@mui/material";
import React from "react";
import useAppContext from "../../hooks/useAppContext";

const AlertMessages = () => {
  const { appContext } = useAppContext();
  const { state } = appContext;

  const { notificationState } = state;
  const { isOpen, messageType, message, timeout } = notificationState;

  const { clearNotification } = useAppContext();
  const handleClose = () => {
    clearNotification();
  };

  return (
    <>
      {isOpen && (
        <Snackbar
          open={isOpen}
          autoHideDuration={timeout || 5000}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={handleClose}
            severity={messageType}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </>
  );
};

export default AlertMessages;
