import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";

const CustomButton = styled(Button)({
  boxShadow: "none !important",
  padding: "14px",
  border: "1px solid",
  lineHeight: 1.5,
  backgroundColor: "#3535f3",
  borderColor: "#00000000",
  fontFamily: "JioType",
  color: "#ffffff",
  borderRadius: "1000px",
  fontWeight: "700",
  fontSize: "1rem",
  letterSpacing: "-0.08px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "#000093",
  },
  [`&.${Button.secondaryBtn}`]: {
    backgroundColor: "transparent !important",
    border: "1px solid #E0E0E0 !important",
    // color: 'black !important'
  },
  "&.Mui-disabled": {
    color: "#ffffff",
    pointerEvents: "inherit",
    cursor: "not-allowed",
    opacity: "0.4",
    backgroundColor: "#000093",
  },
});
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      boxShadow: "none !important",
      padding: "12px",
      border: "1px solid",
      lineHeight: 1.5,
      backgroundColor: "#3535f3",
      borderColor: "#00000000",
      fontFamily: "JioType",
      color: "#ffffff",
      borderRadius: "1000px",
      fontWeight: "700",
      fontSize: "1rem",
      letterSpacing: "-0.08px",
      lineheight: "1.5",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#000093",
      },
    },
    primaryBtn: {
      backgroundColor: "#3535f3",
    },
    secondaryBtn: {
      backgroundColor: "transparent !important",
      border: "1px solid #E0E0E0 !important",
      color: "black !important",
    },
    tertiaryBtn: {
      backgroundColor: "transparent !important",
      border: "1px solid #E0E0E0 !important",
      color: "#000093 !important",
    },
  })
);

export default function BasicButton({
  kind,
  label,
  handleClick,
  children,
  classNames = "",
  loading = false,
  loadingText = "Loading...",
  ...props
}) {
  const classes = useStyles();

  return (
    <>
      <CustomButton
        className={
          kind === "secondary"
            ? classes.secondaryBtn
            : kind === "tertiary"
            ? classes.tertiaryBtn
            : classes.primaryBtn
        }
        onClick={handleClick}
        {...props}>
        {loading ? (
          <div
            style={{
              display: "flex",
              gap: "20px",
              justifyContent: "center",
              alignItems: "center",
            }}>
            <CircularProgress
              size="20px"
              sx={{ color: "white" }}
              thickness={2}
            />{" "}
            {loadingText}
          </div>
        ) : (
          <>
            {label}
            {children}
          </>
        )}
      </CustomButton>
    </>
  );
}
