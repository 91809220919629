import axios from "axios";
const BASE_URL = "/"

export const client = axios.create({
  baseURL: BASE_URL,
  responseType: "text/plain",
  timeout: 50000,
});

const request = (
  {
    method,
    url,
    headers = {},
    data = {},
    responseType = "text/plain",
    auth = {},
  },
  showErrorToast = true
) => {
  const onSuccess = function (response) {
    if (response.status === 200) {
      return response;
    }
  };

  const onError = (error) => {
    return error;
  };

  const payload = {
    method,
    url,
    headers: {
      ...headers,
    },
    data: data,
    responseType,
    auth: auth,
  };
  return client(payload).then(onSuccess).catch(onError);
};

export default request;
