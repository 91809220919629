import request from "./request";

export const login = (userCredentials) => {
  const url = `/jmd-rui/api/v1/auth/login`;
  return request({
    method: "POST",
    url,
    auth: {
      ...userCredentials,
    },
  });
};

export const logout = () => {
  const url = `/jmd-rui/api/v1/auth/logout`;
  return request({
    method: "POST",
    url
  });
};
