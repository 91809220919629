import { Container, Button, Grid, Card, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BasicButton from "../../components/CustomButton";
import Text from "../../components/CustomText";
import useAppContext from "../../hooks/useAppContext";
import { getCookie } from "../../utils/helper";

const Login = () => {
  const [formState, setFormState] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();
  const { loginUser } = useAppContext();
  const { username, password } = formState;
  useEffect(() => {
    const isLoggedIn = getCookie("JMD_TOKEN") || false;
    if (isLoggedIn) {
      navigate("/home", { replace: true });
      return;
    }
  }, []);

  const formInputsChangeHandler = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmitHandler = (event) => {
    event.preventDefault();
    loginUser(formState);
    navigate("/home", { replace: true });
  };

  const getLoginComponent = () => {
    return (
      <div className="login" style={{ height: "100%" }}>
        <Text appearance="heading-s" color="primary-grey-100">
          Login
        </Text>

        <Grid container direction="column" sx={{ height: "100%" }}>
          <form onSubmit={onSubmitHandler} className="form-login">
            <TextField
              type="username"
              label="Username"
              name="username"
              value={username}
              fullWidth
              variant="outlined"
              required
              onChange={formInputsChangeHandler}
              style={{ marginTop: "30px" }}
            />
            <TextField
              type="password"
              label="Password"
              name="password"
              value={password}
              fullWidth
              variant="outlined"
              required
              onChange={formInputsChangeHandler}
              style={{ marginTop: "30px" }}
            />
            <BasicButton
              fullWidth
              label="Login"
              disabled={!password || !username}
              variant="contained"
              type="submit"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                marginTop: "auto",
              }}
            />
          </form>
        </Grid>
      </div>
    );
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      {getLoginComponent()}
    </Grid>
  );
};

export default Login;
