export const InventoryheaderConfig = [
  {
    name: "article",
    sortable: false,
    value: "Article",
    width: "14%",
  },
  {
    name: "store",
    sortable: false,
    value: "Store",
    width: "14%",
  },
  {
    name: "inventoryQuantity",
    sortable: false,
    type: "number",
    value: "Inventory Quantity",
    width: "18%",
  },
  {
    name: "virtualQuantity",
    sortable: false,
    type: "number",
    value: "Virtual Quantity",
    width: "15%",
  },
  {
    name: "safetyStock",
    sortable: true,
    value: "Safety Stock",
    width: "14%",
  },
  {
    name: "status",
    sortable: false,
    value: "Status",
    width: "14%",
  },
  {
    name: "mardAvailability",
    sortable: false,
    value: "MARD Availability",
    width: "14%",
  },
  {
    name: "viewHistory",
    sortable: false,
    value: "View History",
    width: "14%",
  },
];

export const InventorydetailsConfig = [
  {
    inventoryQuantity: 45,
    virtualQuantity: 45,
    timeStamp: "12 Jun 2012",
    store: "John",
    article: "Yang",
    status: "Active",
    viewHistory: "History",
  },
  {
    inventoryQuantity: 45,
    virtualQuantity: 45,
    timeStamp: "12 Jun 2012",
    store: "John",
    article: "Yang",
    status: "Active",
    viewHistory: "History",
  },
  {
    inventoryQuantity: 45,
    virtualQuantity: 45,
    timeStamp: "12 Jun 2012",
    store: "John",
    article: "Yang",
    status: "InActive",
    viewHistory: "History",
  },
  {
    inventoryQuantity: 45,
    virtualQuantity: 45,
    timeStamp: "12 Jun 2012",
    store: "John",
    article: "Yang",
    status: "Active",
    viewHistory: "History",
  },
  {
    inventoryQuantity: 45,
    virtualQuantity: 45,
    timeStamp: "12 Jun 2012",
    store: "John",
    article: "Yang",
    status: "InActive",
    viewHistory: "History",
  },
];

export const BulkheaderConfig = [
  {
    name: "updateId",
    sortable: false,
    value: "Update Id",
    width: "10%",
  },
  {
    name: "updatedBy",
    sortable: false,
    value: "Updated By",
    width: "10%",
  },
  {
    name: "updateType",
    sortable: false,
    value: "Update Type",
    width: "10%",
  },

  {
    name: "success",
    sortable: false,
    type: "number",
    value: "Success",
    width: "10%",
  },
  {
    name: "failure",
    sortable: false,
    value: "Failure",
    width: "10%",
  },
  {
    name: "total",
    sortable: false,
    value: "Total",
    width: "10%",
  },
  {
    name: "timeStamp",
    sortable: false,
    value: "Time Stamp",
    width: "10%",
  },
  {
    name: "action",
    sortable: false,
    value: "Action",
    width: "10%",
  },
];

export const BulkDetailsConfig = [
  {
    updateId: "1234567890",
    timeStamp: "10 Jul 2023",
    total: "123",
    failure: "15",
    success: "108",
    updateType: "Absolute",
    updateBy: "Gokul Tangirala",
  },
];

export const AuditHeaderConfig = [
  {
    name: "article",
    sortable: false,
    value: "Article",
  },
  {
    name: "store",
    sortable: false,
    value: "Store",
  },
  {
    name: "updateId",
    sortable: false,
    type: "number",
    value: "Update Id",
  },
  {
    name: "updatedBy",
    sortable: false,
    type: "number",
    value: "Updated By",
  },
  {
    name: "updateType",
    sortable: false,
    type: "number",
    value: "Update Type",
  },
  {
    name: "quantity",
    sortable: false,
    type: "number",
    value: "Quantity",
  },
  // {
  //   name: "virtualQuantity",
  //   sortable: true,
  //   type: "number",
  //   value: "Virtual Quantity",
  //   width: "15%",
  // },
  {
    name: "articleStatus",
    sortable: false,
    value: "Article Status",
  },

  {
    name: "reason",
    sortable: false,
    value: "Reason",
  },
  {
    name: "updateStatus",
    sortable: false,
    value: "Update Status",
  },
  {
    name: "timeStamp",
    sortable: false,
    value: "Time Stamp",
  },
];

export const BulkHistoryConfig = [
  {
    name: "article",
    sortable: false,
    value: "Article",
    width: "14%",
  },
  {
    name: "store",
    sortable: false,
    value: "Store",
    width: "14%",
  },
  {
    name: "updateId",
    sortable: false,
    value: "Update Id",
    width: "14%",
  },
  {
    name: "updatedBy",
    sortable: false,
    type: "number",
    value: "Updated By",
  },
  {
    name: "updateType",
    sortable: false,
    type: "number",
    value: "Update Type",
  },
  {
    name: "quantity",
    sortable: false,
    type: "number",
    value: "Quantity",
    width: "18%",
  },
  // {
  //   name: "virtualQuantity",
  //   sortable: false,
  //   type: "number",
  //   value: "Virtual Quantity",
  //   width: "15%",
  // },
  // {
  //   name: "safetyStock",
  //   sortable: true,
  //   value: "Safety Stock",
  //   width: "14%",
  // },
  {
    name: "articleStatus",
    sortable: false,
    value: "Article Status",
    width: "14%",
  },
  {
    name: "reason",
    sortable: false,
    value: "Reason",
    width: "14%",
  },
  {
    name: "updateStatus",
    sortable: false,
    value: "Update Status",
    width: "14%",
  },
  
  {
    name: "timeStamp",
    sortable: false,
    value: "Time Stamp",
    width: "14%",
  },
];
