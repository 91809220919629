import { Input, Modal } from "@jds/core";
import moment from "moment";
import React, { useState } from "react";
import useAppContext from "../../hooks/useAppContext";

const InventoryModal = ({
  closed,
  setOpenModal,
  reasonsList,
  activeRow,
  countReset,
  selectedQuantity,
}) => {
  const { showNotification } = useAppContext();
  const [activeReset, setActiveReset] = useState(false);
  const [countResetData, setCountResetData] = useState({
    articleId: activeRow?.article,
    store: activeRow?.store,
    updateType: "",
    articleQuantity: "",
    reason: "",
    deactivatedTime: "",
  });

  const reasonDropdown = reasonsList?.map((itm) => {
    return {
      disabled: false,
      label: itm,
      prefix: undefined,
      value: itm,
    };
  });

  const virtualDelta = () => {
    if (
      selectedQuantity === "virtual" &&
      countResetData.updateType === "DELTA"
    ) {
      return countResetData.articleQuantity > 0;
    } else {
      return true;
    }
  };

  const virtualDeactivation = () => {
    if (selectedQuantity === "virtual") {
      return countResetData.deactivatedTime !== "";
    } else {
      return true;
    }
  };

  const disableSubmit =
    countResetData.reason !== "" &&
    countResetData.articleQuantity !== "" &&
    countResetData.updateType !== "" &&
    virtualDelta() &&
    virtualDeactivation();

  const resetUpdateType =
    selectedQuantity === "safetyStock" ? "SAFETY_STOCK" : "ABSOLUTE";

  const clearFields = () => {
    setCountResetData({
      ...countResetData,
      updateType: "",
      articleQuantity: "",
      reason: "",
      deactivatedTime: "",
    });
    setActiveReset(false);
  };

  const closeModal = () => {
    setOpenModal(true);
    clearFields();
    return true;
  };
  const onSubmit = () => {
    if (selectedQuantity === "virtual" || selectedQuantity === "safetyStock") {
      if (countResetData.articleQuantity >= 0) {
        countReset(countResetData);
        clearFields();
      } else {
        showNotification({
          messageType: "error",
          message: "Invalid virtual Quantity",
          timeOut: 2000,
        });
      }
    } else {
      if (Number.isInteger(Number(countResetData.articleQuantity))) {
        countReset(countResetData);
        clearFields();
      } else {
        showNotification({
          messageType: "error",
          message: "Invalid Inventory Quantity",
          timeOut: 2000,
        });
      }
    }
  };

  const onReset = (checkState) => {
    if (checkState) {
      setCountResetData({
        ...countResetData,
        articleQuantity: 0,
        reason:
          selectedQuantity === "safetyStock"
            ? "SAFETY_STOCK_RESET"
            : "ABSOLUTE_RESET",
        updateType: resetUpdateType,
        deactivatedTime: moment(new Date()).format("L"),
      });
    } else {
      setCountResetData({
        ...countResetData,
        articleQuantity: "",
        reason: "",
        updateType: "",
      });
    }
  };

  return (
    <Modal
      header={
        selectedQuantity === "safetyStock"
          ? "Safety Stock"
          : "Edit Inventory Quantity"
      }
      kind="dialog"
      onClickPrimary={() => onSubmit()}
      onClickSecondary={() => closeModal()}
      onCloseCallback={() => {
        clearFields();
        setOpenModal(true);
      }}
      onRequestClose={() => true}
      primaryCTA={{ title: "Submit", button: { disabled: !disableSubmit } }}
      secondaryCTA="Cancel"
      closed={closed}
    >
      <Input
        type="checkbox"
        label="Reset"
        value={activeReset}
        onChange={(e) => {
          setActiveReset(!activeReset);
          onReset(e.target.checked);
        }}
        checked={activeReset}
      />
      <Input
        id="basic-input"
        label="Inventory Quantity"
        placeholder="Enter Quantity"
        maxLength={250}
        disabled={activeReset}
        type="text"
        name="testInput"
        value={countResetData.articleQuantity}
        onChange={(e) =>
          setCountResetData({
            ...countResetData,
            articleQuantity: e.target.value,
          })
        }
        onInvalid={(e) => e.target.value !== 0}
        onPrefixClick={function noRefCheck() {}}
        onSuffixClick={function noRefCheck() {}}
        stateConfig={{
          errorText: "Custom Error 1",
          invalidError: "Invalid Value",
          requiredError: "This value is required",
          successText: "Success message",
          warningText: "Warning message",
        }}
        step={1}
      />
      <Input
        id="basic-input"
        label="Update Type"
        maxLength={250}
        min={0}
        type="dropdown"
        disabled={activeReset}
        name="testInput"
        value={countResetData.updateType}
        onChange={(e) =>
          setCountResetData({
            ...countResetData,
            updateType: e.target.value,
            reason:
              e.target.value === "SAFETY_STOCK" ? "SAFETY_STOCK_RESET" : "",
          })
        }
        onInvalid={function noRefCheck() {}}
        onPrefixClick={function noRefCheck() {}}
        onSuffixClick={function noRefCheck() {}}
        placeholder="Add Update Type"
        stateConfig={{
          errorText: "Custom Error 1",
          invalidError: "Invalid Value",
          requiredError: "This value is required",
          successText: "Success message",
          warningText: "Warning message",
        }}
        step={1}
        typeConfig={{
          checkbox: {
            indeterminate: false,
          },
          code: {
            length: 2,
          },
          dropdown: {
            items: [
              {
                disabled: selectedQuantity === "safetyStock",
                label: "DELTA",
                prefix: undefined,
                value: "DELTA",
              },
              {
                type: "divider",
              },
              {
                disabled: false,
                label: "ABSOLUTE",
                prefix: undefined,
                value:
                  selectedQuantity === "safetyStock"
                    ? "SAFETY_STOCK"
                    : "ABSOLUTE",
              },
            ],
          },
          range: {
            editableTextBox: true,
          },
          toggle: {
            labelPosition: "left",
          },
        }}
      />
      {selectedQuantity !== "safetyStock" && (
        <Input
          style={{ maxHeight: "60px" }}
          id="basic-input"
          label="Reaason for Change"
          placeholder="Select Reason"
          max={100}
          maxLength={250}
          disabled={activeReset}
          min={0}
          type="dropdown"
          name="testInput"
          value={countResetData.reason}
          onChange={(e) =>
            setCountResetData({
              ...countResetData,
              reason: e.target.value,
            })
          }
          onInvalid={function noRefCheck() {}}
          onPrefixClick={function noRefCheck() {}}
          onSuffixClick={function noRefCheck() {}}
          stateConfig={{
            errorText: "Custom Error 1",
            invalidError: "Invalid Value",
            requiredError: "This value is required",
            successText: "Success message",
            warningText: "Warning message",
          }}
          step={1}
          typeConfig={{
            checkbox: {
              indeterminate: false,
            },
            code: {
              length: 2,
            },
            dropdown: {
              items: reasonDropdown,
            },
            range: {
              editableTextBox: true,
            },
            toggle: {
              labelPosition: "left",
            },
          }}
        />
      )}
      {selectedQuantity === "virtual" && (
        <Input
          id="basic-input"
          label="Deactivation Date"
          placeholder="mm/dd/yyyy"
          maxLength={10}
          disabled={activeReset}
          type="text"
          name="testInput"
          value={countResetData.deactivatedTime}
          onChange={(e) =>
            setCountResetData({
              ...countResetData,
              deactivatedTime: e.target.value,
            })
          }
          onInvalid={(e) => e.target.value !== 0}
          onPrefixClick={function noRefCheck() {}}
          onSuffixClick={function noRefCheck() {}}
          stateConfig={{
            errorText: "Custom Error 1",
            invalidError: "Invalid Value",
            requiredError: "This value is required",
            successText: "Success message",
            warningText: "Warning message",
          }}
          step={1}
        />
      )}
    </Modal>
  );
};

export default InventoryModal;
