import React, { useCallback, useEffect, useState } from "react";
import { Button, Icon, Input } from "@jds/core";
import SearchTable from "../../components/SearchTable";
import UploadFile from "../../components/UploadFile";
import {
  BulkheaderConfig,
  BulkHistoryConfig,
} from "../../components/SearchTable/configData";
import "../../styles/baseCountReset.scss";
import {
  downloadCsv,
  DownloadIndividualDetails,
  individualBulkHistoryDetails,
  uploadDataFile,
  uploadHistory,
} from "../../api/inventory";
import useAppContext from "../../hooks/useAppContext";
import "./style.scss";

const VirtualInventory = () => {
  const { showNotification } = useAppContext();
  const [quantityType, setQuantityType] = useState("");
  const [fileData, setFileData] = useState();
  const [uploadHistoryDetails, setUploadHistoryDetails] = useState([]);
  const [historyPagination, setHistoryPagination] = useState({
    pageNo: 0,
    pageSize: 10,
    totalPages: 4,
  });
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [indiHistoryPagination, setIndiHistoryPagination] = useState({
    pageNo: 0,
    pageSize: 10,
    totalPages: 4,
  });
  const [uploadId, setUploadId] = useState();
  const updateType =
    quantityType !== "VIRTUAL" ? quantityType : "VIRTUAL_ABSOLUTE";

  const [showBulkHistory, setShowBulkHistory] = useState(true);
  const clearFileds = () => {
    setFileData();
  };
  const uploadCSVFile = async () => {
    const res = await uploadDataFile(fileData, updateType);
    if (res?.response.status === 200) {
      fetchHistoryDetails(0);
      clearFileds();
      showNotification({
        messageType: "success",
        message: "File Uploaded Successfully",
        timeOut: 2000,
      });
    } else {
      clearFileds();
      showNotification({
        messageType: "error",
        message: res?.response.data.data.errorMessage,
        timeOut: 2000,
      });
    }
  };
  const [individualHistoryDetails, SetIndividualHistoryDetails] = useState();

  const exportCSVFile = (headers) => {
    // if (headers) {
    //   items = [headers];
    // }
    // Convert Object to JSON
    const exportedFilename = `${quantityType} TEMPLATE`;
    const blob = new Blob([headers], { type: "text/csv;charset=utf-8;" });

    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, exportedFilename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const onShowDetails = (itm) => {
    setShowBulkHistory(false);
    setUploadId(itm.uploadId);
    onIndividualHistoryDetails(itm.uploadId);
  };

  const onIndividualHistoryDetails = async (itm, pageNo) => {
    let res = await individualBulkHistoryDetails(
      itm,
      pageNo ?? indiHistoryPagination.pageNo
    );
    SetIndividualHistoryDetails(res?.data?.data);
    setIndiHistoryPagination({
      ...indiHistoryPagination,
      pageSize: Number(res?.headers?.total_result),
      totalPages: Number(res?.headers?.total_pages),
    });
  };

  const fetchHistoryDetails = useCallback(
    async (pageNo) => {
      const res = await uploadHistory(pageNo);
      setUploadHistoryDetails(res?.data?.data);
      setHistoryPagination({
        ...historyPagination,
        pageSize: Number(res?.headers?.total_result),
        totalPages: Number(res?.headers?.total_pages),
      });
    },
    [historyPagination]
  );

  useEffect(() => {
    if (isInitialRender) {
      fetchHistoryDetails(historyPagination.pageNo);
      // setIsInitialRender((isInitialRender) => !isInitialRender);
    }
  }, []);

  const downloadCsvTemplate = async (data) => {
    let res = await downloadCsv(data);
    if (res.status === 200) {
      exportCSVFile(res?.data.split(","));
      showNotification({
        messageType: "success",
        message: "Template Downloaded Successfully",
        timeOut: 2000,
      });
    } else {
      showNotification({
        messageType: "error",
        message: "Unable To Download Template",
        timeOut: 2000,
      });
    }
  };
  const disableSubmit = () => {
    if (quantityType === "VIRTUAL" || quantityType === "SAFETY_STOCK") {
      return true;
    } else {
      return quantityType === "ABSOLUTE" || quantityType === "DELTA";
    }
  };
  const onViewHistory = async (data) => {
    let res = await DownloadIndividualDetails(data);
    if (res.status === 200) {
      exportCSVFile(res?.data.split(","));
      showNotification({
        messageType: "success",
        message: "History Downloaded Successfully",
        timeOut: 2000,
      });
    } else {
      showNotification({
        messageType: "error",
        message: "Unable To Download History",
        timeOut: 2000,
      });
    }
  };

  const handleChangePage = (newPage) => {
    setHistoryPagination({
      ...historyPagination,
      pageNo: newPage,
    });
    fetchHistoryDetails(newPage);
  };

  const handleIndiHistoryChangePage = (newPage) => {
    setHistoryPagination({
      ...indiHistoryPagination,
      pageNo: newPage,
    });
    onIndividualHistoryDetails(uploadId, newPage);
  };

  return (
    <div className="upload-container">
      <div className="header">
        <p className="page-title">Upload Inventory</p>
        <Button
          kind="secondary"
          className="csv-template"
          disabled={quantityType === ""}
          onClick={() => downloadCsvTemplate(quantityType)}
        >
          <Icon ic="IcDownload"></Icon>
          <span>Download CSV Template</span>
        </Button>
      </div>
      <div className="upload-inventory">
        <div className="input-details">
          <Input
            id="basic-input"
            label="Quantity Type"
            max={100}
            maxLength={250}
            min={0}
            type="dropdown"
            name="testInput"
            placeholder="Select Type"
            required
            onChange={(e) => {
              clearFileds();
              setQuantityType(e.target.value);
            }}
            onInvalid={function noRefCheck() {}}
            onPrefixClick={function noRefCheck() {}}
            onSuffixClick={function noRefCheck() {}}
            stateConfig={{
              errorText: "Custom Error 1",
              invalidError: "Invalid Value",
              requiredError: "This value is required",
              successText: "Success message",
              warningText: "Warning message",
            }}
            step={1}
            typeConfig={{
              checkbox: {
                indeterminate: false,
              },
              code: {
                length: 2,
              },
              dropdown: {
                items: [
                  {
                    disabled: false,
                    label: "Virtual Quantity",
                    prefix: undefined,
                    value: "VIRTUAL",
                  },
                  {
                    type: "divider",
                  },
                  {
                    disabled: false,
                    label: "Inventory Quantity",
                    prefix: undefined,
                    value: "ABSOLUTE",
                  },
                  {
                    type: "divider",
                  },
                  {
                    disabled: false,
                    label: "Safety Stock",
                    prefix: undefined,
                    value: "SAFETY_STOCK",
                  },
                ],
              },
              range: {
                editableTextBox: true,
              },
              toggle: {
                labelPosition: "left",
              },
            }}
          />
          <Input
            id="basic-input"
            label="Upload Type"
            placeholder="Select Type"
            max={100}
            maxLength={250}
            min={0}
            disabled={true}
            type="dropdown"
            name="testInput"
            onChange={(e) => {
              setQuantityType(e.target.value);
            }}
            onInvalid={function noRefCheck() {}}
            onPrefixClick={function noRefCheck() {}}
            onSuffixClick={function noRefCheck() {}}
            stateConfig={{
              errorText: "Custom Error 1",
              invalidError: "Invalid Value",
              requiredError: "This value is required",
              successText: "Success message",
              warningText: "Warning message",
            }}
            step={1}
            typeConfig={{
              checkbox: {
                indeterminate: false,
              },
              code: {
                length: 6,
              },
              dropdown: {
                items: [
                  {
                    disabled: false,
                    label: "Delta",
                    prefix: undefined,
                    value: "DELTA",
                  },
                  {
                    type: "divider",
                  },
                  {
                    disabled: false,
                    label: "Absolute",
                    prefix: undefined,
                    value: "ABSOLUTE",
                  },
                  {
                    type: "divider",
                  },
                ],
              },
              range: {
                editableTextBox: true,
              },
              toggle: {
                labelPosition: "left",
              },
            }}
          />
        </div>

        <UploadFile
          disableSubmit={disableSubmit}
          setFileData={setFileData}
          fileData={fileData}
          uploadCsvFile={uploadCSVFile}
        />
      </div>
      {showBulkHistory ? (
        <SearchTable
          headers={BulkheaderConfig}
          tableHead="Update History"
          rows={uploadHistoryDetails}
          tableName="bulkDetails"
          onHistory={onViewHistory}
          onShowDetails={onShowDetails}
          SetIndividualHistoryDetails={SetIndividualHistoryDetails}
          pagination={historyPagination}
          setPagination={setHistoryPagination}
          handleChangePage={handleChangePage}
          clearAll
        />
      ) : (
        <SearchTable
          headers={BulkHistoryConfig}
          tableHead="Update History"
          rows={individualHistoryDetails}
          tableName="bulkHistoryDetails"
          setShowBulkHistory={setShowBulkHistory}
          backButton
          setShowInventoryTable={setShowBulkHistory}
          pagination={indiHistoryPagination}
          setPagination={setIndiHistoryPagination}
          handleChangePage={handleIndiHistoryChangePage}
        />
      )}
    </div>
  );
};
export default VirtualInventory;
