import * as React from "react";
import { styled } from "@mui/system";

const textStyle = {
  "body-m": {
    fontWeight: 500,
    fontSize: "1.125rem",
    letterSpacing: "-0.005em",
    lineHeight: 1,
  },
  "body-s": {
    fontWeight: 500,
    fontSize: "1rem",
    letterSpacing: "-0.005em",
    lineHeight: 1.5,
  },
  "heading-m": {
    fontWeight: 900,
    fontSize: "2.5rem",
    letterSpacing: "-1.2px",
    lineHeight: 1,
  },
  "heading-s": {
    fontWeight: 900,
    fontSize: "2.0rem",
    letterSpacing: "-0.96px",
    lineHeight: "1",
  },
  "heading-xs": {
    fontWeight: 900,
    fontSize: "1.5rem",
    letterSpacing: "-0.72px",
    lineHeight: "1.16",
  },
  "body-m-bold": {
    fontWeight: 700,
    fontSize: "1.125rem",
    letterSpacing: "-0.09px",
    lineHeight: 1.33,
  },
  "body-xxs": {
    fontWeight: 500,
    fontSize: "0.75rem",
    letterSpacing: "-0.06px",
    lineHeight: 1.3333333333,
  },
  "body-s-bold": {
    fontWeight: 700,
    fontSize: "1rem",
    letterSpacing: "-0.08px",
    lineHeight: 1.5,
  },
  "body-xxs-bold": {
    fontWeight: 700,
    fontSize: "0.875rem",
    letterSpacing: "-0.07px",
    lineHeight: 1.4285714286,
  },
  "body-xs": {
    fontWeight: 500,
    fontSize: "0.875rem",
    letterSpacing: "-0.07px",
    lineHeight: 1.4285714286,
  },
};
const CustomeText = styled("div")({
  fontFamily: "JioType",
  fontStyle: "normal",
});

export default function Text(props) {
  const { color, appearance, children, style, ...rest } = props;
  return (
    <CustomeText
      id="standard-basic"
      style={{
        color: `var(--color-${color})`,
        ...textStyle[appearance],
        ...style,
      }}
      {...rest}>
      {children}
    </CustomeText>
  );
}
