import React from "react";
import { getCookie } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";

const PrivateRoutes = () => {
    const navigate = useNavigate();
    const isLoggedIn = getCookie("JMD_TOKEN") || false;
    return isLoggedIn ? <Outlet /> : navigate("/login");

    //SAML
    //return isLoggedIn ? <Outlet /> : window.location.replace("/login");
};

export default PrivateRoutes;
