import { useContext } from "react";
import { AppContext } from "../context/context";

export function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export const useLoader = () => {
  const appContext = useContext(AppContext);
  const { dispatch } = appContext;
  const startLoader = () => {
    dispatch({ type: "SET_LOADER", loader: true });
  };
  const stopLoader = () => {
    dispatch({ type: "SET_LOADER", loader: false });
  };
  return { startLoader, stopLoader };
};
