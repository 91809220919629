import React from "react";

import { AppContext } from "../context/context";
import { useNavigate } from "react-router-dom";
import { login, logout } from "../api/auth";

const useAppContext = () => {
  const appContext = React.useContext(AppContext);
  const navigate = useNavigate();
  if (!appContext) {
    throw new Error("AppContext must be used inside ContextProvider");
  }

  const { dispatch } = appContext;

  async function loginUser(userData) {
    try {
      const { dispatch } = appContext;
      const response = await login(userData);
      dispatch({ type: "USER_LOGIN_SUCCESSFULL", token: response.data });
      dispatch({ type: "SET_LOADER", loader: false });
      navigate("/home");

      // SAML
      // window.location.href = '/login';
    } catch (error) {
      console.log(error)

      dispatch({ type: "SET_LOADER", loader: false });
      showNotification({
        isOpen: true,
        messageType: "error",
        message: error?.response?.data?.data?.errorMessage,
      });
    }
  }

  async function logoutUser() {
    try {
      dispatch({ type: "SET_LOADER" });
      await logout();
      dispatch({ type: "LOGOUT" });
      navigate("/logout");

      // SAML
      //window.location.href = '/logout';
    } catch (e) {
      dispatch({ type: "SET_LOADER ", loader: false });
    }
  }

  function showNotification(config) {
    // debugger
    if (!config.message) {
      config.message = "Something went wrong. Please try again";
    }
    dispatch({ type: "SHOW_NOTIFICATION", payload: config });
  }
  function clearNotification() {
    // debugger
    dispatch({ type: "CLEAR_NOTIFICATION" });
  }
  return {
    loginUser,
    logoutUser,
    clearNotification,
    showNotification,
    appContext,
  };
};

export default useAppContext;
