import React, { useState } from "react";
import { Button, Icon, Input } from "@jds/core";
import "./style.scss";

const UploadFile = ({
  disableSubmit,
  setFileData,
  fileData,
  uploadCsvFile,
}) => {
  const [fileName, setFileName] = useState("");
  return (
    <div className="upload-file">
      {fileData && fileData.size < 2097152 && (
        <div className="successful-container">
          <Icon ic="IcSuccessColored" kind="background-bold" size="xl" />
          <div>{`${fileName} Uploaded Successfully`}</div>
        </div>
      )}

      {!fileData && (
        <Button kind="tertiary" className="upload-button">
          <Input
            type="file"
            kind="teritiary"
            id="upload-file"
            onChange={(e) => {
              const test = e?.target.value.split("\\");
              setFileName(test[2]);
              setFileData(e.target.files[0]);
            }}
            style={{ opacity: 0 }}
            disabled={!disableSubmit()}
            accept=".csv"
          />
          <label for="upload-file">
            <Icon ic="IcUpload1" kind="background-bold" size="xl" />
            <div>
              <p>
                <span>Browse</span> file to upload.
              </p>
              <p className="max-size">CSV of maximum 2mb</p>
            </div>
          </label>
        </Button>
      )}
      {fileData && fileData.size > 2097152 && (
        <div className="upload-size">
          <p>
            <span>ReUpload</span> file to upload.
          </p>
          <p className="error-size">CSV supported of maximum 2MB</p>
        </div>
      )}
      <div className="button-container">
        {fileData && (
          <div>
            <Button kind="teritiary" className="submit-button">
              <Input
                type="file"
                kind="teritiary"
                id="reupload-file"
                onChange={(e) => {
                  const test = e?.target.value.split("\\");
                  setFileName(test[2]);
                  setFileData(e.target.files[0]);
                }}
                style={{ opacity: 0, height: "0px", margin: "0px" }}
                accept=".csv"
              />
              <label for="reupload-file">Re-Upload</label>
            </Button>
          </div>
        )}
        <Button
          kind="primary"
          className="submit-button"
          disabled={
            !disableSubmit || !Boolean(fileData) || fileData?.size > 2097152
          }
          onClick={() => uploadCsvFile()}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default UploadFile;
