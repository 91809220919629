import { Button, Icon, Input, Table } from "@jds/core";
import React from "react";
import CustomPagination from "../../components/CustomPagination";
import useAppContext from "../../hooks/useAppContext";
import "./style.scss";
import { activeFlagChange } from "../../api/inventory";
import NoResults from "../NoResults";
import viewHistory from "../../assets/images/viewHistory.svg";
import moment from "moment";

const SearchTable = ({
  setOpenModal,
  openModal,
  headers,
  rows,
  showHistory,
  tableHead,
  backButton,
  tableName,
  setShowInventoryTable,
  fetchReasons,
  setActiveRow,
  setSelectedQuantity,
  pagination,
  setPagination,
  handleChangePage,
  onHistory,
  fetchArticleDetails,
  onShowDetails,
  onClear,
  clearAll,
}) => {
  const { showNotification } = useAppContext();
  const updateDetails = async (obj) => {
    const res = await activeFlagChange(obj);
    if (res?.data?.status === "FAILED") {
      showNotification({
        messageType: "error",
        message: res?.response?.data?.data?.errorMessage,
        timeOut: 2000,
      });
    } else {
      fetchArticleDetails();
      showNotification({
        messageType: "success",
        message: "Flag Status Changed Successfully",
        timeOut: 2000,
      });
    }
  };
  const updateFlagChange = (obj, status) => {
    if (status === 1) {
      showNotification({
        messageType: "error",
        message: "Cannot change status to inactive",
        timeOut: 2000,
      });
    } else {
      updateDetails(obj);
    }
  };
  const inventoryDetails = rows?.map((itm, id) => {
    const updateFlagData = {
      articleId: itm.article,
      store: itm.store,
      isActive: true,
    };
    return {
      article: itm?.article,
      store: itm?.store,
      inventoryQuantity: (
        <div className="edit-container">
          <p>{itm?.quantity !== null ? itm?.quantity : 0}</p>
          <Button
            kind="tertiary"
            onClick={() => {
              setOpenModal(!openModal);
              fetchReasons();
              setActiveRow(itm);
              setSelectedQuantity("inventory");
            }}
          >
            <Icon ic="IcEditPen" size="s"></Icon>
          </Button>
        </div>
      ),
      virtualQuantity: (
        <div className="edit-container">
          <p>{itm?.virtualQuantity !== null ? itm?.virtualQuantity : 0}</p>
          <Button
            kind="tertiary"
            onClick={() => {
              setOpenModal(!openModal);
              setActiveRow(itm);
              fetchReasons();
              setSelectedQuantity("virtual");
            }}
          >
            <Icon ic="IcEditPen" size="s"></Icon>
          </Button>
        </div>
      ),
      safetyStock: (
        <div className="edit-container">
          <p>
            {itm?.safetyStockQuantity !== null ? itm?.safetyStockQuantity : 0}
          </p>
          <Button
            kind="tertiary"
            onClick={() => {
              setOpenModal(!openModal);
              setActiveRow(itm);
              fetchReasons();
              setSelectedQuantity("safetyStock");
            }}
          >
            <Icon ic="IcEditPen" size="s"></Icon>
          </Button>
        </div>
      ),
      // timeStamp: itm?.timeStamp,
      status: (
        <div className="status-row">
          <Input
            type="toggle"
            checked={itm?.active === 1}
            onClick={() => updateFlagChange(updateFlagData, itm?.active)}
          ></Input>
          {itm?.active === 1 ? (
            <p style={{ color: "green", margin: "4px" }}>Active</p>
          ) : (
            <p style={{ color: "red", margin: "4px" }}>In Aactive</p>
          )}
        </div>
      ),
      mardAvailability:
        itm.mardAvailability === 1 ? (
          <p style={{ color: "green", margin: "4px" }}>True</p>
        ) : (
          <p style={{ color: "red", margin: "4px" }}>False</p>
        ),
      viewHistory: (
        <div>
          <Button
            kind="tertiary"
            onClick={() => {
              showHistory(itm);
              setActiveRow(itm);
            }}
          >
            View
          </Button>
        </div>
      ),
    };
  });

  const auditDetails = rows?.map((itm, id) => {
    return {
      article: itm?.article,
      store: itm?.store,
      quantity: (
        <div className="edit-container">
          <p>{itm?.quantity !== null ? itm?.quantity : 0}</p>
        </div>
      ),

      articleStatus: (
        <div className="status-row">
          <p>{itm.showIsActive}</p>
        </div>
      ),
      reason: itm?.reason,
      updateId: itm.id,
      updatedBy: itm.uploadedBy,
      updateType: itm.updateType,
      updateStatus: itm.updateStatus,
      timeStamp: itm.updatedDate,
    };
  });

  const bulkDetails = rows?.map((itm, id) => {
    return {
      updateId: itm.uploadId ?? 0,
      timeStamp: moment(itm.createdTime).format("D-MM-Y hh:mm:ss") ?? 0,
      total: itm.count ?? 0,
      failure: itm.failure ?? 0,
      success: itm.success ?? 0,
      updateType: itm.updateType,
      updatedBy: itm.uploadedBy,
      action: (
        <div className="action-container">
          <Button
            kind="tertiary"
            disabled={itm.uploadId === null}
            onClick={() => {
              onShowDetails(itm);
            }}
          >
            <Icon ic="IcCopy"></Icon>
          </Button>

          <hr />
          <Button
            kind="tertiary"
            onClick={() => onHistory(itm.uploadId)}
            disabled={itm.uploadId === null}
          >
            <Icon ic="IcDownload"></Icon>
          </Button>
        </div>
      ),
    };
  });
  const bulkHistoryDetails = rows?.map((itm, id) => {
    return {
      article: itm.article ?? "gokul",
      store: itm.store ?? 0,
      updateId: itm.uploadId ?? 0,
      quantity: itm.quantity ?? 0,
      articleStatus: itm.isActive === 0 ? "InActive" : "Active",
      timeStamp: moment(itm.createdTime).format("D-MM-Y hh:mm:ss") ?? 0,
      updateType: itm.updateType,
      updatedBy: itm.uploadedBy,
      reason: itm.reason,
      updateStatus: itm.updateStatus,
    };
  });

  const totalTableDetails = {
    inventoryDetails,
    auditDetails,
    bulkDetails,
    bulkHistoryDetails,
  };

  return (
    <div className="search-article-container">
      <div className="table-title">
        <div>
          {backButton && (
            <Button
              kind="tertiary"
              size="l"
              color="black"
              onClick={() => setShowInventoryTable(true)}
            >
              <Icon ic="IcBack" />
            </Button>
          )}
          <p className="table-head">{tableHead}</p>
        </div>
        {rows !== undefined && !clearAll && (
          <Button kind="secondary" size="l" onClick={() => onClear()}>
            Clear All
          </Button>
        )}
      </div>

      <div className="search-table">
        <Table
          columnDivider
          density="relaxed"
          headers={headers}
          rows={totalTableDetails[tableName] ?? []}
        />
        {pagination?.totalPages > 0 && (
          <CustomPagination
            totalPages={pagination.totalPages}
            pageSize={pagination.pageSize}
            currentPage={pagination.pageNo}
            pagination={pagination}
            setPagination={setPagination}
            handleChangePage={handleChangePage}
            className="table-pagination"
          />
        )}
      </div>
    </div>
  );
};

export default SearchTable;
