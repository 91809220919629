import React, { useEffect, useState } from "react";
import "./styles.scss";
import NotFoundImg from "../../assets/images/general-error.svg";

export default function Unauthorized() {
  const [redirectTime, setRedirectTime] = useState(5);
  useEffect(() => {
    setTimeout(() => {
      window.location.replace("/logout");
    }, 5000);
  }, [redirectTime]);

  useEffect(() => {
    const interval = setInterval(() => setRedirectTime(redirectTime - 1), 1000);
    if (redirectTime <= 1) {
      clearInterval(interval);
      window.location.replace("/logout");
    }
  });

  return (
    <div className="error-page">
      <div className="imgwrapper">
        <img src={NotFoundImg} alt="not found" />
      </div>
      <div className="error-info">
        <p className="title">Unauthorized access.</p>
        <p>{`The page will be redirected in ${redirectTime} seconds`}</p>
      </div>
    </div>
  );
}
