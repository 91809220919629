import React from "react";
import { TabItem, Tabs } from "@jds/core";
import "./style.scss";

const NavTabs = ({ setShowSingleInventory }) => {
  return (
    <Tabs
      activeTab={0}
      onChange={(e) => setShowSingleInventory(e === 0)}
      tabs={2}
    >
      <TabItem label="Single Inventory"></TabItem>
      <TabItem label="Bulk Inventory"></TabItem>
    </Tabs>
  );
};

export default NavTabs;
