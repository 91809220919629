import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./container/PrivateRoutes";
import VirtualInventory from "./container/VirtualInventory";
import Navbar from "./container/Navbar";
import useAppContext from "./hooks/useAppContext";
import Home from "./container/Home";
import BackDropUI from "./container/BackDropUI";
import AlertMessages from "./container/AlertMessages/AlertMessages";
import InventoryCount from "./container/InventoryCount";
import Unauthorized from "./container/Unauthorized";
import Login from "./container/Login";
import LoggedOut from "./container/LoggedOut";
import { client } from "./api/request";
import { useLoader } from "./utils/helper";
import "./styles/main.scss";

function App() {
  const { appContext } = useAppContext();
  const { startLoader, stopLoader } = useLoader();
  const { state } = appContext;
  const hideNavBar = window.location.pathname === "/unauthorized-user";
  const { loading, notificationState } = state;
  const { isOpen, messageType, message } = notificationState;

  client.interceptors.request.use(
    (req) => {
      startLoader();
      return req;
    },
    (err) => {
      stopLoader();
      return Promise.reject(err);
    }
  );

  client.interceptors.response.use(
    (res) => {
      stopLoader();
      return res;
    },
    (err) => {
      stopLoader();
      if (err.response && err.response.status === 401) {
        window.location = `${window.location.origin}/login`;
      }
      return Promise.reject(err);
    }
  );

  return (
    <div>
      <AlertMessages open={isOpen} severity={messageType} message={message} />
      <BackDropUI open={loading} />
      {!hideNavBar && <Navbar />}
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/home" element={<Home />} exact />
          <Route
            path="/inventory/view-update-bulk"
            element={<VirtualInventory />}
            exact
          />
          <Route
            path="/inventory/view-update-single"
            element={<InventoryCount />}
            exact
          />
        </Route>
        <Route path="/login" element={<Login />} exact/>
        <Route path="/logout" element={<LoggedOut />} exact />
        <Route path="/logout-success" element={<LoggedOut />} exact />
        <Route path="/unauthorized-user" element={<Unauthorized />} exact />
        <Route path="*" element={<Navigate to="/home" replace />} />
      </Routes>
    </div>
  );
}

export default App;
