import { AppBar, Toolbar } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getCookie } from "../../utils/helper";
import Text from "../../components/CustomText";
import useAppContext from "../../hooks/useAppContext";
import logo from "../../assets/images/logo.svg";
import { Icon, Button } from "@jds/core";
// import { Skeleton, TabItem, Tabs } from "@jds/core";

const Navbar = () => {
  const { logoutUser, loginUser } = useAppContext();
  const [showLogout, setShowLogout] = useState(false);
  const isLoggedIn = getCookie("JMD_TOKEN") || false;

  const handleLogout = () => {
    logoutUser();
  };

  const handleLogin = () => {
    loginUser();
  };

  const logoutDetails = () => {
    setShowLogout(!showLogout);
  };

  const onLogout = () => {
    if (isLoggedIn) {
      handleLogout();
    } else {
      handleLogin();
    }
  };

  return (
    <div>
      <div>
        <AppBar position="static" sx={{ background: "#3535f3" }}>
          <Toolbar>
            <Link to="/home">
              <img
                alt="jiomart_digital_logo"
                style={{ width: "60px", padding: "8px" }}
                src={logo}
              />
            </Link>
            <Text color="primary-20" appearance="body-m-bold">
              JMD RUI Operations Manager
            </Text>
            <React.Fragment>
              <Button
                kind="tertiary"
                onClick={() => logoutDetails()}
                style={{
                  marginLeft: "auto",
                  backgroundColor: "white",
                  height: "50px",
                  width: "50px",
                  borderRadius: "50px",
                  padding: "0px",
                }}
              >
                <Icon ic="IcProfile" size="l" color="primary"></Icon>
              </Button>
              <Button onClick={() => onLogout()}>
                {isLoggedIn ? "Logout" : "Login"}
              </Button>
            </React.Fragment>
          </Toolbar>
        </AppBar>
      </div>

      {/* <div>
        <Tabs
          activeTab={0}
          onChange={function noRefCheck() {}}
          tabs={2}
          fullWidth
        >
          <TabItem
            label="Single Inventory"
            style={{ width: "200px" }}
          ></TabItem>
          <TabItem label="Bulk Inventory"></TabItem>
        </Tabs>
      </div> */}
    </div>
  );
};

export default Navbar;
