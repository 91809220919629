import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export default function PaginationRounded({
  totalPages,
  pagination,
  setPagination,
  handleChangePage,
}) {
  // const handleChangePage = (newPage) => {
  //   setPagination({
  //     ...pagination,
  //     pageNo: newPage,
  //   });
  // };

  return (
    <Stack
      spacing={2}
      style={{ display: "flex", width: "100%", margin: "16px auto" }}
    >
      <Pagination
        count={totalPages}
        onChange={(e, val) => {
          handleChangePage(val - 1);
        }}
        shape="rounded"
        color="primary"
      />
    </Stack>
  );
}
