import React, { useState } from "react";
import { FormLabel, TextField } from "@mui/material";
import moment from "moment";
import GppBadIcon from "@mui/icons-material/GppBad";
import {
  viewInventoryCount,
  viewAuditTrail,
  absoluteCountReset,
  getReasons,
} from "../../api/inventory";
import useAppContext from "../../hooks/useAppContext";

import SearchTable from "../../components/SearchTable";
import {
  AuditHeaderConfig,
  InventoryheaderConfig,
} from "../../components/SearchTable/configData";
import InventoryModal from "../../components/Modal";
import "../../styles/baseCountReset.scss";
import "./style.scss";
import { Button } from "@jds/core";

const InventoryCount = () => {
  const [articleId, setArticleId] = useState("");
  const [articleData, setArticleData] = useState([]);
  const [selectedQuantity, setSelectedQuantity] = useState();
  const [reasonsList, setReasonsList] = useState();
  const [auditData, setAuditData] = useState([]);

  const [activeRow, setActiveRow] = useState([]);
  const [showInventoryTable, setShowInventoryTable] = useState(true);
  const [openModal, setOpenModal] = useState(true);
  const [pagination, setPagination] = useState({
    pageNo: 6,
    pageSize: 10,
    totalPages: 0,
  });
  const { showNotification } = useAppContext();
  const { validRecords, invalidRecords, notFoundRecords } = articleData;

  const fetchAuditDetails = async (payload) => {
    const obj = {
      articleId: payload.article,
      store: payload.store,
      pageNo: payload?.pageNo ?? 0,
    };
    let res = await viewAuditTrail(obj);

    if (res.status === 200) {
      const tempAuditData = [];
      res?.data?.data.forEach((itm) => {
        const {
          updateType,
          quantity,
          uploadedBy,
          createdDate,
          isActive,
          updateStatus,
          article,
          store,
          reason,
          id,
        } = itm;

        const showIsActive = isActive !== 0 ? "Active" : "Inactive";

        tempAuditData.push({
          updateType,
          quantity,
          uploadedBy,
          updatedDate: moment(createdDate).format("D-MM-Y hh:mm:ss"),
          showIsActive,
          updateStatus,
          article,
          store,
          reason,
          id,
        });
      });
      setPagination({
        ...pagination,
        pageSize: Number(res?.headers?.total_result),
        totalPages: Number(res?.headers?.total_pages),
      });

      setAuditData(tempAuditData);
    }
    if (res?.response?.status === 500) {
      setAuditData([]);
      showNotification({
        messageType: "error",
        message: res?.response?.data?.data?.errorMessage,
        timeOut: 2000,
      });
    }
  };

  const resetToDefault = () => {
    setOpenModal(true);
    fetchArticleDetails();
    setSelectedQuantity();
  };

  const handleChangePage = (newPage) => {
    setPagination({
      ...pagination,
      pageNo: newPage,
    });
    const payload = {
      article: activeRow.article,
      store: activeRow.store,
      pageNo: newPage,
    };

    fetchAuditDetails(payload);
  };

  const fetchReasons = async () => {
    let res = await getReasons();
    if (res.status === 200) {
      const { data } = res;
      setReasonsList(data?.data ?? []);
    }
  };

  const showHistory = (row) => {
    fetchAuditDetails(row);
    setShowInventoryTable(false);
  };

  const fetchArticleDetails = async () => {
    let res = await viewInventoryCount(articleId);
    if (res.status === 200) {
      setArticleData(res?.data?.data ?? []);
    }
    if (res?.response?.status === 400) {
      setArticleData([]);
      showNotification({
        messageType: "error",
        message: res?.response?.data?.data?.errorMessage,
        timeOut: 2000,
      });
    }
  };

  const onUpdate = (row) => {
    setActiveRow([row]);
    fetchReasons();
  };

  const onClear = () => {
    setArticleData([]);
    setArticleId("");
  };

  const countReset = async (rowData) => {
    const formattedUpdateType =
      selectedQuantity === "virtual"
        ? "VIRTUAL_" + rowData?.updateType
        : rowData?.updateType;
    const obj = {
      articleId: activeRow?.article,
      store: activeRow?.store,
      updateType: formattedUpdateType,
      articleQuantity: rowData?.articleQuantity,
      reason: rowData?.reason,
      deactivatedTime: rowData?.deactivatedTime,
    };
    let res = await absoluteCountReset(obj, selectedQuantity);
    if (res?.response?.data?.status === "ERROR") {
      showNotification({
        messageType: "error",
        message: res?.response?.data?.data?.errorMessage,
        timeOut: 2000,
      });
    } else {
      showNotification({
        messageType: "success",
        message: "Count Changed Successfully",
        timeOut: 2000,
      });
      resetToDefault();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        padding: "20px 128px",
        backgroundColor: "#E0E0E0",
        minHeight: "85vh",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "white",
          margin: "0px 16px",
          padding: "16px",
          borderRadius: "8px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "70%",
          }}
        >
          <FormLabel
            sx={{
              fontWeight: "600",
              marginRight: "10%",
            }}
          >
            Article_Store ID
          </FormLabel>
          <TextField
            variant="standard"
            name="input-search"
            value={articleId}
            inputProps={{ style: { textAlign: "left" } }}
            onChange={(e) => {
              setArticleId(e.target.value);
            }}
            type="text"
            fullWidth
            onKeyPress={(event) => {
              if (!/[0-9A-Z_,]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            multiline
            error={invalidRecords?.length > 0 || notFoundRecords?.length > 0}
          />
          <div style={{ displa: "flex", fontSize: "12px" }}>
            {invalidRecords?.length > 0 && (
              <p style={{ color: "red" }}>
                {invalidRecords?.map((itm, index) => (
                  <span key={index}>
                    <GppBadIcon
                      sx={{ fill: "red", height: "10px", width: "10px" }}
                    />
                    {itm}
                  </span>
                ))}{" "}
                Invalid Articles
              </p>
            )}
            {notFoundRecords?.length > 0 && (
              <p style={{ color: "red" }}>
                {notFoundRecords?.map((itm, index) => (
                  <span key={index}>
                    <GppBadIcon
                      sx={{
                        fill: "red",
                        height: "10px",
                        width: "10px",
                      }}
                    />
                    {itm}
                  </span>
                ))}{" "}
                Id's not found.
              </p>
            )}
          </div>
          <p>
            Add article_store for eg. 581110278_S573. You can add maximum 20
            values.
          </p>
        </div>

        <div style={{ marginLeft: "2%" }}>
          {/* <BasicButton
            sx={{ height: "10px" }}
            variant="contained"
            label="Fetch Details"
            disabled={!(articleId.length > 13)}
            }
          /> */}
          <Button
            onClick={() => {
              fetchArticleDetails();
              setShowInventoryTable(true);
            }}
          >
            Search
          </Button>
        </div>
      </div>
      {showInventoryTable && (
        <SearchTable
          rows={validRecords}
          headers={InventoryheaderConfig}
          onUpdate={onUpdate}
          showHistory={showHistory}
          tableHead="Inventory Details"
          tableName="inventoryDetails"
          setOpenModal={setOpenModal}
          openModal={openModal}
          fetchReasons={fetchReasons}
          reasonsList={reasonsList}
          setActiveRow={setActiveRow}
          setSelectedQuantity={setSelectedQuantity}
          fetchArticleDetails={fetchArticleDetails}
          onClear={onClear}
        />
      )}

      {!showInventoryTable && (
        <SearchTable
          rows={auditData}
          headers={AuditHeaderConfig}
          pagination={pagination}
          setPagination={setPagination}
          tableHead="History Details"
          backButton
          tableName="auditDetails"
          setShowInventoryTable={setShowInventoryTable}
          handleChangePage={handleChangePage}
          disableTable={!showInventoryTable}
          fetchArticleDetails={fetchArticleDetails}
        />
      )}

      <InventoryModal
        closed={openModal}
        setOpenModal={setOpenModal}
        reasonsList={reasonsList}
        activeRow={activeRow}
        openModal={openModal}
        countReset={countReset}
        selectedQuantity={selectedQuantity}
        fetchArticleDetails={fetchArticleDetails}
      />
    </div>
  );
};

export default InventoryCount;
